exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/ourWork.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-services-data-entry-js": () => import("./../../../src/pages/services/dataEntry.js" /* webpackChunkName: "component---src-pages-services-data-entry-js" */),
  "component---src-pages-services-development-js": () => import("./../../../src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-devops-js": () => import("./../../../src/pages/services/devops.js" /* webpackChunkName: "component---src-pages-services-devops-js" */),
  "component---src-pages-services-enterprise-architecture-js": () => import("./../../../src/pages/services/enterpriseArchitecture.js" /* webpackChunkName: "component---src-pages-services-enterprise-architecture-js" */),
  "component---src-pages-services-it-support-js": () => import("./../../../src/pages/services/itSupport.js" /* webpackChunkName: "component---src-pages-services-it-support-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-manual-testing-js": () => import("./../../../src/pages/services/manualTesting.js" /* webpackChunkName: "component---src-pages-services-manual-testing-js" */),
  "component---src-pages-services-qa-automation-js": () => import("./../../../src/pages/services/qaAutomation.js" /* webpackChunkName: "component---src-pages-services-qa-automation-js" */)
}

